<template>
  <div class="canvas-container">
    <div class="ml-1 mb-50">
      <label style="margin-right: 0.3rem; font-size: 0.857rem; color: #d0d2d6;">
        Tree Layout
      </label>
      <b-form-checkbox
        :checked="showChart"
        name="check-button"
        switch
        inline
        @change="toggleView"
      >
        Chart Layout
      </b-form-checkbox>
    </div>
    <div v-if="false">
      <ontology-view :update-object="updateObject" />
    </div>
    <b-overlay
      :show="loadingAll"
      variant="transparent"
      :opacity="1"
      rounded="sm"
      class="canvas"
    >
      <ontology-view-joint v-if="showChart && !loadingAll" :update-object="updateObject" @sidebar="onSidebar" />
      <ecosystem-view v-if="!showChart" @sidebar="onSidebar" />
    </b-overlay>
    <b-sidebar
      title="Entity Details"
      bg-variant="white"
      width="30vw"
      right
      :visible="isSidebarVisible"
      class="scroll-when-full"
      @hidden="isSidebarVisible = false"
    >
      <EntityDetailsFn @clicked="doUpdate()" />
    </b-sidebar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OntologyView from '@/components/Domain/OntologyView.vue'
import EntityDetailsFn from '@/components/Domain/EntityDetailsFn.vue'
import EcosystemView from '@/components/Domain/EcosystemView.vue'
import OntologyViewJoint from '@/components/Domain/OntologyTreeViewJoint/OntologyViewJoint.vue'

export default {
  name: 'FunctionALlocation',
  components: {
    OntologyViewJoint,
    EntityDetailsFn,
    OntologyView,
    EcosystemView,
  },
  data() {
    return {
      nCount: 0,
      loadingAll: false,
      showChart: true,
      updateObject: null,
      isSidebarVisible: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['composition_tree', 'selected_entity2']),
    ...mapState({
      props: state => state.model.lookup,
    }),
    currentRouteName() {
      return this.$route.name
    },
  },
  mounted() {
    const curRoute = this.props.Functions
    if (this.showChart === false) {
      this.loadingAll = true
      this.$store.dispatch('domainModel/getCompTreeDataD3', curRoute || null).then(() => {
        console.debug('domainModel/compTreeData on load: ', this.composition_tree)
        this.nCount = this.composition_tree.nodes.length
        this.loadingAll = false
      })
    }
  },
  methods: {
    onSidebar(isVisible) {
      this.isSidebarVisible = isVisible
    },
    // Fully refresh the store
    refreshGraph() {
      if (this.showChart === false) {
        this.loadingAll = true
        const curRoute = this.props.Functions
        this.$store.dispatch('domainModel/getCompTreeDataD3', curRoute || null).then(() => {
          console.debug('Refreshed Tree View: ', this.composition_tree)
          this.nCount = this.composition_tree.nodes.length
          this.loadingAll = false
        })
      }
    },
    doUpdate() {
      const nId = this.selected_entity2.context.details.id
      console.log('Updated Object: ', this.selected_entity2)
      this.updateObject = this.selected_entity2
    },
    // Toggle the force on/off via the `isForce` global variable
    toggleView() {
      if (this.showChart) {
        this.showChart = false
      } else {
        this.showChart = true
      }
      this.refreshGraph()
    },
  },
}
</script>

<style scoped lang="scss">
body {
  height: 60vh;
  box-sizing: border-box;
  margin: 0;

  .content-wrapper {
    .content-body {
      width: 100%;
      height: 100%;
    }
  }

  .canvas {
    width: 100%;
    height: 100%;

    .joint-paper {
      border: 1px solid #000000;
    }
  }

  .toolbar-text {
    display: none;
  }
}
</style>
<style lang="scss">
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';

.canvas-container {
  width: revert;
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  top: calc($navbar-height + $content-padding);
}

.scroll-when-full {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}
.entity-pane {
  border-left: 1px dotted;
  overflow-y: auto;
}
</style>
