<template>
  <div>
    <b-badge class="mr-1" variant="primary">
      {{ componentBadge }}
    </b-badge>
    <div :title="cpt.qualified_name ? cpt.qualified_name : cpt.name" class="mr-1 text-ellipsis">
      {{ cpt.qualified_name ? cpt.qualified_name : cpt.name }}
    </div>
    <div v-if="cpt.aliases" class="text-muted" title="Aliases">{{ cpt.aliases.join(', ') }}</div>
    <b-row>
      <b-col cols="8">
        <div class="form-label-group mt-2 mb-0">
          <b-form-textarea
            id="txtJustification"
            v-model="cpt.justification"
            :rows="1"
            size="sm"
            placeholder="Justification"
            title="Justification"
            @change="triggerSave"
            debounce="500"
          />
          <label for="txtJustification">Justification</label>
        </div>
      </b-col>
      <b-col>
        <b-form-group
          class="form-label-group mt-2 mb-0"
          id="fieldset-1"
          description="Select the release from which this allocation is effective (empty = always)"
          label="Effective from Release"
          label-for="fromRelease"
          size="sm"
          title="Justification"
          @change="triggerSave"
          debounce="500"
        >
          <b-form-select
            id="fromRelease"
            v-model="cpt.start"
            size="sm"
            :options="releaseOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'ComponentAllocatedListItem',
  props: {
    componentBadge: { type: String, default: '' },
    component: { type: Object, required: true },
    triggerFn: { type: Function, required: true },
  },
  data() {
    return {
      valueStart: '',
      valueEnd: '',
    }
  },
  setup(props) {
    return {
      cpt: props.component,
      triggerSave: props.triggerFn,
    }
  },
  computed: {
    ...mapState({
      releases: state => state.releases.releases,
    }),
    releaseOptions() {
      return this.releases.map(x => ({
        value: x.id,
        html: `<b>${x.name}</b> <span class="text-muted">(${x.start_date})</span>`,
      }))
    },
  },
}
</script>

<style scoped lang="scss">

</style>
